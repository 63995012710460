function addNewWithdrawal(state: any, withdrawal: any) {
	let { withdrawals } = state;
	withdrawals.push(withdrawal);
	return {
		withdrawals: withdrawals,
		...state,
	};
}

export { addNewWithdrawal };
