import React from 'react';
import { ApolloProvider, HttpLink, InMemoryCache, ApolloClient } from '@apollo/client';
import aws4 from 'aws4';
import { Auth } from 'aws-amplify';
const cache = new InMemoryCache();

let uri = `${process.env.REACT_APP_SECURE_HTTP}://${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PREFIX}/adminv2`;

export const client = new ApolloClient({
	link: new HttpLink({
		uri: uri,
		fetch: awsGraphqlFetch,
	}),
	cache: cache,
});

async function awsGraphqlFetch(uri: any, options: any) {
	options = options || {};
	let signable = {
		service: 'execute-api',
		method: 'POST',
		region: process.env.REACT_APP_AWS_REGION!,
		host: process.env.REACT_APP_API_HOST!,
		path: `${process.env.REACT_APP_API_PREFIX}/adminv2`,
		body: options.body,
	};
	const cred = await Auth.currentCredentials();
	var signer = new aws4.RequestSigner(signable, {
		secretAccessKey: cred.secretAccessKey,
		accessKeyId: cred.accessKeyId,
		sessionToken: cred.sessionToken,
	});
	signer.sign();
	// @ts-expect-error TS(2339): Property 'headers' does not exist on type '{ servi... Remove this comment to see the full error message
	options.headers = signable.headers;
	return fetch(uri, signable);
}

function SimplicityClientWrapper(props: any) {
	return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export default SimplicityClientWrapper;
