import React from 'react';
import './App.css';
import Layout from './modules/layout/Layout';
import { Paper, makeStyles, Box } from '@material-ui/core';

import { WithdrawProvider } from './modules/pages/withdrawals/WithdrawContext';
// import Withdrawals from "./modules/pages/withdrawals/FormContainer";
import {
	withAuthenticator,
	SignIn,
	ConfirmSignIn,
	RequireNewPassword,
	ForgotPassword,
	Loading,
	TOTPSetup,
} from 'aws-amplify-react';
import ApiClientProvider from './clients/ApiClient';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		maxWidth: '850px',
		backgroundColor: '#f3f6f8',
		margin: 'auto',
	},
}));

function App(props: any) {
	const classes = useStyles();
	return (
		<Layout>
			<Paper className={classes.root}>
				<Box p={4}>
					<WithdrawProvider>
						<ApiClientProvider>{props.children}</ApiClientProvider>
					</WithdrawProvider>
				</Box>
			</Paper>
		</Layout>
	);
}

// @ts-expect-error TS(2345): Argument of type '{ includeGreetings: boolean; sig... Remove this comment to see the full error message
export default withAuthenticator(App, {
	includeGreetings: false,
	// authentication
	signUpConfig: {
		isSignUpDisplayed: false,
	},
	// hideDefault: true
	authenticatorComponents: [
		<SignIn override={true} />,
		// @ts-expect-error TS(2322): Type '{ override: boolean; }' is not assignable to... Remove this comment to see the full error message

		<RequireNewPassword override={true} />,
		// @ts-expect-error TS(2322): Type '{ override: boolean; }' is not assignable to... Remove this comment to see the full error message

		<Loading override={true} />,
		// @ts-expect-error TS(2322): Type '{ override: boolean; }' is not assignable to... Remove this comment to see the full error message

		<ConfirmSignIn override={true} />,
		// @ts-expect-error TS(2322): Type '{ override: boolean; }' is not assignable to... Remove this comment to see the full error message

		<ForgotPassword overide={true} />,
		// @ts-expect-error TS(2322): Type '{ override: boolean; }' is not assignable to... Remove this comment to see the full error message

		<TOTPSetup override={true} />,
	],
});
