import React, { useState } from 'react';
import { addNewWithdrawal } from './WithdrawReducers';
import { merge } from 'ramda';
import { Withdrawal } from './types';

// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
let WithdrawalsStateContext = React.createContext();
// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
let WithdrawalsDispatchContext = React.createContext();
// @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
const FilterByProductContext = React.createContext();
type FilterByProductContextType = {
	filterProduct: string | null;
	setFilterProduct: React.Dispatch<React.SetStateAction<string | null>>;
};
function withdrawalReducer(state: any, action: any) {
	switch (action.type) {
		case 'ADD_NEW':
			return addNewWithdrawal(state, action.payload);
		case 'SHOW_LIST':
			const { showList } = state;
			return {
				...state,
				showList: !showList,
			};
		case 'WITHDRAWAL_SIM':
		case 'WITHDRAWAL_DETAILS':
		case 'WITHDRAWAL_SEARCH':
			return merge(state)(action.payload);
		case 'WITHDRAWAL_FILTERS':
			const { filters } = action.payload;
			if (state.filters === filters) return merge(state)({ filters: '' });
			return merge(state)(action.payload);

		case 'WITHDRAWAL_INVESTMENT_BENEFICIARY':
			if (!(action.payload === undefined)) {
				return {
					...state,
					beneficiaries: action.payload.data,
				};
			}
			break;
		case 'RESET':
			return {
				showList: true,
				withdrawals: [],
				search: '',
			};
		default: {
			throw new Error(`Unhandled action type :${action.type}`);
		}
	}
}

function resetForm(dispatch: any) {
	dispatch({
		type: 'RESET',
	});
}

function listView(dispatch: any) {
	dispatch({
		type: 'SHOW_LIST',
	});
}

async function newWithDraw(dispatch: any, withdraw: any) {
	dispatch({
		type: 'ADD_NEW',
		payload: withdraw,
	});
}

async function withdrawalForUserHelper(dispatch: any, userDetails: any) {
	dispatch({
		type: 'WITHDRAWAL_SIM',
		payload: userDetails,
	});
}
async function withdrawalDetailsHelper(dispatch: any, details: Partial<Withdrawal>) {
	dispatch({
		type: 'WITHDRAWAL_DETAILS',
		payload: details,
	});
}

async function withdrawSearch(dispatch: any, search: any) {
	dispatch({
		type: 'WITHDRAWAL_SEARCH',
		payload: search,
	});
}

async function withdrawGenericPayload(dispatch: any, event: any, payload: any) {
	dispatch({
		type: event,
		payload: payload,
	});
}

function withdrawalBeneficiaryHelper(dispatch: any, beneficiaries: any) {
	dispatch({
		type: 'WITHDRAWAL_INVESTMENT_BENEFICIARY',
		payload: beneficiaries,
	});
}

function WithdrawProvider({ children }: any) {
	let [state, dispatch] = React.useReducer(withdrawalReducer, {
		withdrawals: [],
		draft: {},
		showList: true,
		search: '',
		filters: '',
	});
	const [filterProduct, setFilterProduct] = useState('All');
	return (
		<FilterByProductContext.Provider value={{ filterProduct, setFilterProduct }}>
			<WithdrawalsDispatchContext.Provider value={dispatch}>
				<WithdrawalsStateContext.Provider value={state}>{children}</WithdrawalsStateContext.Provider>
			</WithdrawalsDispatchContext.Provider>
		</FilterByProductContext.Provider>
	);
}

function useWithdrawState(): Withdrawal {
	let context = React.useContext(WithdrawalsStateContext) as Withdrawal;
	if (context === undefined) {
		throw new Error('useWithdrawState must be used within a Withdraw provider');
	}
	return context;
}

function useFilterByProduct(): FilterByProductContextType {
	let context = React.useContext(FilterByProductContext) as FilterByProductContextType;
	if (context === undefined) {
		throw new Error('useWithdrawState must be used within a Withdraw provider');
	}
	return context;
}
function useWithdrawDispatch() {
	let context = React.useContext(WithdrawalsDispatchContext);
	if (context === undefined) {
		throw new Error('useWithdrawDispatch must be used within a WithdrawProvider');
	}
	return context;
}

export {
	WithdrawProvider,
	newWithDraw,
	useWithdrawState,
	useWithdrawDispatch,
	useFilterByProduct,
	withdrawalForUserHelper,
	withdrawalDetailsHelper,
	resetForm,
	withdrawalBeneficiaryHelper,
	listView,
	withdrawSearch,
	withdrawGenericPayload,
};
