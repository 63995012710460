import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { MuiThemeProvider as ThemeProvider, createMuiTheme } from '@material-ui/core';
import theme from './context/theme';
import Amplify from 'aws-amplify';

import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';

// @ts-expect-error TS(2345): Argument of type '{ spacing: number; typography: {... Remove this comment to see the full error message
const _theme = createMuiTheme(theme);

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Identity Pool ID
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		// REQUIRED - Amazon Cognito Region
		region: process.env.REACT_APP_AWS_REGION,
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
	},
});

// @ts-expect-error TS(7031): Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
const UserRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props: any) => (
			<LayoutProvider>
				<ThemeProvider theme={_theme}>
					<Suspense fallback={<div>Loading</div>}>
						<App>
							<Component {...props} {...rest} />
						</App>
					</Suspense>
				</ThemeProvider>
			</LayoutProvider>
		)}
	></Route>
);

const ListContainer = React.lazy(() => import('./modules/pages/withdrawals/ListContainer'));
const FormContainer = React.lazy(() => import('./modules/pages/withdrawals/FormContainer'));

function AdminRoutes() {
	let location = useLocation();
	return (
		<>
			<Switch location={location}>
				<UserRoute exact path="/" component={ListContainer} props />
				<UserRoute exact path="/withdrawal/list" component={ListContainer} props />
				<UserRoute exact path="/withdrawal/new" component={FormContainer} props />
				<UserRoute exact path="/withdrawal/show" component={FormContainer} props />
				<Route path="*" render={() => <Redirect to="/" />} />
			</Switch>
			{/* <Switch location={location}>
      <UserRoute exact path="/withdrawal/list" component={ListContainer} props />
      <UserRoute exact path="/withdrawal/new" component={FormContainer} props />
      <UserRoute exact path="/withdrawal/show" component={FormContainer} props />
    </Switch> */}
		</>
	);
}

ReactDOM.render(
	<React.Fragment>
		<Router basename="/">
			<AdminRoutes />
		</Router>
	</React.Fragment>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
